let allArticles = [
  {
    "date": "December 2020",
    "date2": "2020-12",
    "frontPage": true,
    "favorite": true,
    "image": "2020-12_bloomberg.png",
    "link": "https://www.bloomberg.com/news/features/2020-12-16/covid-pandemic-microbiomes-could-be-key-to-stopping-spread-of-future-viruses",
    "pdf": "2020-12_bloomberg.pdf",
    "publication": "Bloomberg Businessweek",
    "title": "How To Heal a Building"
  },
  {
    "date": "February 2020",
    "date2": "2020-02",
    "frontPage": true,
    "favorite": false,
    "image": "2020-02_bloomberg.png",
    "link": "https://www.bloomberg.com/news/features/2020-02-04/no-city-hates-its-landlords-like-berlin-does",
    "pdf": "2020-02_bloomberg.pdf",
    "publication": "Bloomberg Businessweek",
    "title": "No City Hates Its Landlords Like Berlin Does"
  },
  {
    "date": "September 2019",
    "date2": "2019-09",
    "frontPage": false,
    "favorite": false,
    "image": "2019-09_bloomberg.png",
    "link": "https://www.bloomberg.com/news/features/2019-09-19/bayer-s-monsanto-purchase-looks-worse-with-each-roundup-verdict",
    "pdf": "2019-09_bloomberg.pdf",
    "publication": "Bloomberg Businessweek",
    "title": "With Each Roundup Verdict, Bayer's Monsanto Purchase Looks Worse"
  },
  {
    "date": "June 2019",
    "date2": "2019-06",
    "frontPage": true,
    "favorite": true,
    "image": "2019-06_bloomberg.jpg",
    "link": "https://www.bloomberg.com/news/features/2019-06-06/towing-an-iceberg-one-captain-s-plan-to-bring-drinking-water-to-4-million-people",
    "pdf": "2019-06_bloomberg.pdf",
    "publication": "Bloomberg Businessweek",
    "title": "How to Tow an Iceberg from Antarctica"
  },
  {
    "date": "April 2019",
    "date2": "2019-04",
    "frontPage": true,
    "favorite": true,
    "image": "2019-04_bloomberg.jpg",
    "link": "https://www.bloomberg.com/news/features/2019-04-22/even-clorox-and-unilever-want-the-booming-bacteria-business-to-thrive",
    "pdf": "2019-04_bloomberg.pdf",
    "publication": "Bloomberg Businessweek",
    "title": "Bring On the Bacteria"
  },
  {
    "date": "September 2017",
    "date2": "2017-09",
    "frontPage": true,
    "favorite": true,
    "image": "2017-09_bloomberg.jpg",
    "link": "https://www.bloomberg.com/news/features/2017-09-21/nestl-makes-billions-bottling-water-it-pays-nearly-nothing-for",
    "pdf": "2017-09_bloomberg.pdf",
    "publication": "Bloomberg Businessweek",
    "title": "Nestle Makes Billions Bottling Water It Pays Nearly Nothing For"
  },
  {
    "date": "April 2017",
    "date2": "2017-04",
    "frontPage": false,
    "favorite": false,
    "image": "2017-04_bloomberg.jpg",
    "link": "https://www.bloomberg.com/news/articles/2017-04-26/ifly-and-the-rise-of-indoor-sky-diving",
    "pdf": "2017-04_bloomberg.pdf",
    "publication": "Bloomberg Businessweek",
    "title": "IFly and the Rise of Indoor Sky Diving"
  },
  {
    "date": "March 2017",
    "date2": "2017-03",
    "frontPage": true,
    "favorite": true,
    "image": "2017-03_bloomberg.jpg",
    "link": "https://www.bloomberg.com/news/features/2017-03-22/how-dilbert-s-scott-adams-got-hypnotized-by-trump",
    "pdf": "2017-03_bloomberg.pdf",
    "publication": "Bloomberg Businessweek",
    "title": "How Scott Adams Got Hypnotized by Trump"
  },
  {
    "date": "July 2016",
    "date2": "2016-07",
    "frontPage": true,
    "favorite": true,
    "image": "2016-07_bloomberg.png",
    "link": "http://www.bloomberg.com/features/2016-newvistas-mormon-utopia/",
    "pdf": "2016-07_bloomberg.pdf",
    "publication": "Bloomberg Businessweek",
    "title": "A Mormon Tycoon Wants to Build Joseph Smith's Mega-Utopia in Vermont"
  },
  {
    "date": "December 2015",
    "date2": "2015-12",
    "frontPage": true,
    "favorite": false,
    "image": "2015-12_bloomberg.jpg",
    "link": "http://www.bloomberg.com/features/2015-whale-shark-diving/",
    "pdf": "2015-12_bloomberg.pdf",
    "publication": "Bloomberg Businessweek",
    "title": "A Giant Fish Story"
  },
  {
    "date": "August 2015",
    "date2": "2015-08",
    "frontPage": false,
    "favorite": true,
    "image": "2015-08_bloomberg.jpg",
    "link": "http://www.bloomberg.com/graphics/2015-ketamine-depression-treatment/",
    "pdf": "2015-08_bloomberg.pdf",
    "publication": "Bloomberg Businessweek",
    "title": "The Ketamine Cure"
  },
  {
    "date": "July 2015",
    "date2": "2015-07",
    "frontPage": false,
    "favorite": false,
    "image": "2015-07_bloomberg.jpg",
    "link": "http://www.bloomberg.com/news/articles/2015-07-22/cosmetics-startup-aobiome-sells-bacteria-for-healthier-skin",
    "pdf": "2015-07_bloomberg.pdf",
    "publication": "Bloomberg Businessweek",
    "title": "The Bacteria Solution"
  },
  {
    "date": "May 2015",
    "date2": "2015-05",
    "frontPage": true,
    "favorite": false,
    "image": "2015-05_bloomberg.png",
    "link": "http://www.bloomberg.com/news/articles/2015-05-07/lighting-designer-lindsey-adelman-manages-department-of-fantasy",
    "pdf": "2015-05_bloomberg.pdf",
    "publication": "Bloomberg Businessweek",
    "title": "Mink Coats and Meditation: Inside the Studio That Makes Mutant Chandeliers"
  },
  {
    "date": "April 2015",
    "date2": "2015-04",
    "frontPage": false,
    "favorite": false,
    "image": "2015-04_bloomberg.jpg",
    "link": "http://www.bloomberg.com/news/articles/2015-04-09/black-list-podcast-brings-hollywood-s-best-unproduced-scripts-to-life",
    "pdf": "2015-04_bloomberg.pdf",
    "publication": "Bloomberg Businessweek",
    "title": "Hear the Movies You Can't See"
  },
  {
    "date": "February 2015",
    "date2": "2015-02",
    "frontPage": true,
    "favorite": true,
    "image": "2015-02_bloomberg.jpg",
    "link": "http://www.bloomberg.com/news/articles/2015-02-27/marina-abramovic-tries-to-monetize-performance-art",
    "pdf": "2015-02_bloomberg.pdf",
    "publication": "Bloomberg Businessweek",
    "title": "Marina Abramovic On Monetizing the Ephemeral"
  },
  {
    "date": "February 2015",
    "date2": "2015-02",
    "frontPage": true,
    "favorite": false,
    "image": "2015-02_bloomberg_a.png",
    "link": "http://www.bloomberg.com/news/articles/2015-02-05/steve-einzig-can-book-that-nostalgia-act-for-your-wedding",
    "pdf": "2015-02_bloomberg_a.pdf",
    "publication": "Bloomberg Businessweek",
    "title": "The Wedding Singers"
  },
  {
    "date": "January 2014",
    "date2": "2014-01",
    "frontPage": false,
    "favorite": false,
    "image": "2014-01_bloomberg.png",
    "link": "http://www.bloomberg.com/news/articles/2015-01-30/addiction-treatment-goes-public-aac-s-recovery-center-empire",
    "pdf": "2014-01_bloomberg.pdf",
    "publication": "Bloomberg Businessweek",
    "title": "Addiction Treatment Goes Public"
  },
  {
    "date": "November 2014",
    "date2": "2014-11",
    "frontPage": false,
    "favorite": false,
    "image": "2014-11_bloomberg.png",
    "link": "http://www.bloomberg.com/bw/articles/2014-11-20/the-big-business-of-ugly-christmas-sweaters",
    "pdf": "2014-11_bloomberg.pdf",
    "publication": "Bloomberg Businessweek",
    "title": "The Big Business of Ugly Christmas Sweaters"
  },
  {
    "date": "November 2014",
    "date2": "2014-11",
    "frontPage": false,
    "favorite": false,
    "image": "2014-11_bloomberg_a.png",
    "link": "http://www.bloomberg.com/bw/articles/2014-11-13/glossier-emily-weisss-makeup-for-cool-girls",
    "pdf": "2014-11_bloomberg_a.pdf",
    "publication": "Bloomberg Businessweek",
    "title": "Glossier: Emily Weiss's Makeup for Cool Girls"
  },
  {
    "date": "October 2014",
    "date2": "2014-10",
    "frontPage": false,
    "favorite": false,
    "image": "2014-10_bloomberg.png",
    "link": "http://www.businessweek.com/articles/2014-10-01/chinese-student-influx-compels-u-dot-s-dot-private-schools-to-adapt",
    "pdf": "2014-10_bloomberg.pdf",
    "publication": "Bloomberg Businessweek",
    "title": "U.S. Private High Schools Accommodate Influx of Chinese Students"
  },
  {
    "date": "September 2014",
    "date2": "2014-09",
    "frontPage": false,
    "favorite": false,
    "image": "2014-09_bloomberg.png",
    "link": "http://www.businessweek.com/articles/2014-09-11/loreals-makeup-genius-app-cosmetics-counter-goes-digital",
    "pdf": "2014-09_bloomberg.pdf",
    "publication": "Bloomberg Businessweek",
    "title": "L'Or\u00e9al's Makeup Genius App: The Cosmetics Counter Goes Digital"
  },
  {
    "date": "August 2014",
    "date2": "2014-08",
    "frontPage": true,
    "favorite": false,
    "image": "2014-08_bloomberg.jpg",
    "link": "http://www.businessweek.com/articles/2014-08-21/juliet-marines-ghost-boat-will-be-hard-sell-to-u-dot-s-dot-navy",
    "pdf": "2014-08_bloomberg.pdf",
    "publication": "Bloomberg Businessweek",
    "title": "This Stealth Attack Boat May Be Too Innovative for the Pentagon"
  },
  {
    "date": "May 2014",
    "date2": "2014-05",
    "frontPage": true,
    "favorite": false,
    "image": "2014-05_bloomberg.jpg",
    "link": "http://www.businessweek.com/articles/2014-05-22/allis-markham-hollywood-taxidermys-rising-star",
    "pdf": "2014-05_bloomberg.pdf",
    "publication": "Bloomberg Businessweek",
    "title": "Allis Markham, Hollywood Taxidermy's Rising Star"
  },
  {
    "date": "April 2014",
    "date2": "2014-04",
    "frontPage": false,
    "favorite": false,
    "image": "2014-04_bloomberg.png",
    "link": "http://www.businessweek.com/articles/2014-04-24/upspeaks-use-by-smart-men-and-women-and-what-it-means",
    "pdf": null,
    "publication": "Bloomberg Businessweek",
    "title": "What Does How You Talk Have to Do With How You Get Ahead?"
  },
  {
    "date": "March 2014",
    "date2": "2014-03",
    "frontPage": true,
    "favorite": false,
    "image": "2014-03_bloomberg.png",
    "link": "http://www.businessweek.com/articles/2014-03-20/andrew-hessels-autodesk-team-seeks-crowdsourced-cancer-cure",
    "pdf": "2014-03_bloomberg.pdf",
    "publication": "Bloomberg Businessweek",
    "title": "Andrew Hessel's Autodesk Team Seeks Crowdsourced Cancer Cure"
  },
  {
    "date": "February 2014",
    "date2": "2014-02",
    "frontPage": false,
    "favorite": false,
    "image": "2014-02_bloomberg.png",
    "link": "http://www.businessweek.com/articles/2014-02-13/dreams-about-work-your-nightmares-decoded",
    "pdf": "2014-02_bloomberg.pdf",
    "publication": "Bloomberg Businessweek",
    "title": "Dreams About Work: Your Nightmares Decoded"
  },
  {
    "date": "January 2014",
    "date2": "2014-01",
    "frontPage": false,
    "favorite": false,
    "image": "2014-01_bloomberg_a.png",
    "link": "http://www.businessweek.com/articles/2014-01-09/art-remba-lets-you-rent-the-masterpiece-of-your-dreams",
    "pdf": null,
    "publication": "Bloomberg Businessweek",
    "title": "Art Remba Lets You Rent the Masterpiece of Your Dreams"
  },
  {
    "date": "January 2014",
    "date2": "2014-01",
    "frontPage": true,
    "favorite": false,
    "image": "2014-01_bloomberg_b.jpg",
    "link": "http://www.businessweek.com/articles/2014-01-09/the-minds-eye-synesthesia-has-business-benefits",
    "pdf": "2014-01_bloomberg_b.pdf",
    "publication": "Bloomberg Businessweek",
    "title": "The Mind's Eye: Synesthesia Has Business Benefits"
  },
  {
    "date": "December 2013",
    "date2": "2013-12",
    "frontPage": true,
    "favorite": false,
    "image": "2013-12_bloomberg.jpg",
    "link": "http://www.businessweek.com/articles/2013-12-12/byron-allens-entertainment-factory-produces-low-budget-tv-shows",
    "pdf": "2013-12_bloomberg.pdf",
    "publication": "Bloomberg Businessweek",
    "title": "Byron Allen, Former Stand-Up Comic, Runs the 'Walmart of Television'"
  },
  {
    "date": "December 2013",
    "date2": "2013-12",
    "frontPage": false,
    "favorite": false,
    "image": "2013-12_bloomberg_a.png",
    "link": "http://www.businessweek.com/articles/2013-12-05/the-rise-and-fall-of-blackberry-an-oral-history",
    "pdf": null,
    "publication": "Bloomberg Businessweek",
    "title": "The Rise and Fall of BlackBerry: An Oral History"
  },
  {
    "date": "August 2013",
    "date2": "2013-08",
    "frontPage": false,
    "favorite": false,
    "image": "2013-08_bloomberg.png",
    "link": "http://www.businessweek.com/articles/2013-08-08/dealer-david-zwirner-on-the-art-market-and-collectors-like-steve-cohen",
    "pdf": null,
    "publication": "Bloomberg Businessweek",
    "title": "Dealer David Zwirner on the Art Market and Collectors Like Steve Cohen"
  },
  {
    "date": "June 2013",
    "date2": "2013-06",
    "frontPage": true,
    "favorite": false,
    "image": "2013-06_bloomberg.jpg",
    "link": "http://www.businessweek.com/articles/2013-06-06/mansudae-art-studio-north-koreas-colossal-monument-factory",
    "pdf": "2013-06_bloomberg.pdf",
    "publication": "Bloomberg Businessweek",
    "title": "Mansudae Art Studio, North Korea's Colossal Monument Factory"
  },
  {
    "date": "March 2013",
    "date2": "2013-03",
    "frontPage": false,
    "favorite": false,
    "image": "2013-03_bloomberg.png",
    "link": "http://www.businessweek.com/articles/2013-03-21/european-soccers-master-of-match-fixing",
    "pdf": null,
    "publication": "Bloomberg Businessweek",
    "title": "European Soccer's Master of Match-Fixing"
  },
  {
    "date": "January 2013",
    "date2": "2013-01",
    "frontPage": true,
    "favorite": false,
    "image": "2013-01_bloomberg.jpg",
    "link": "http://www.businessweek.com/articles/2013-01-24/jeanne-gang-and-the-greening-of-chicago",
    "pdf": "2013-01_bloomberg.pdf",
    "publication": "Bloomberg Businessweek",
    "title": "Jeanne Gang and the Greening of Chicago"
  },
  {
    "date": "October 2012",
    "date2": "2012-10",
    "frontPage": true,
    "favorite": false,
    "image": "2012-10_bloomberg.png",
    "link": "http://www.businessweek.com/articles/2012-10-30/nyc-rats-stronger-than-sandy",
    "pdf": null,
    "publication": "Bloomberg Businessweek",
    "title": "NYC Rats: Stronger Than Sandy"
  },
  {
    "date": "September 2012",
    "date2": "2012-09",
    "frontPage": false,
    "favorite": false,
    "image": "2012-09_bloomberg.png",
    "link": "http://www.businessweek.com/articles/2012-09-13/in-california-public-school-parents-stage-a-coup",
    "pdf": "2012-09_bloomberg.pdf",
    "publication": "Bloomberg Businessweek",
    "title": "In California, Public School Parents Stage a Coup"
  },
  {
    "date": "July 2012",
    "date2": "2012-07",
    "frontPage": true,
    "favorite": false,
    "image": "2012-07_bloomberg.png",
    "link": "http://www.businessweek.com/articles/2012-07-10/how-the-mormons-make-money",
    "pdf": "2012-07_bloomberg.pdf",
    "publication": "Bloomberg Businessweek",
    "title": "How the Mormons Make Money"
  },
  {
    "date": "February 2012",
    "date2": "2012-02",
    "frontPage": false,
    "favorite": false,
    "image": "2012-02_bloomberg.jpg",
    "link": "http://www.businessweek.com/articles/2012-02-29/the-germany-website-copy-machine",
    "pdf": "2012-02_bloomberg.pdf",
    "publication": "Bloomberg Businessweek",
    "title": "How Three Germans Are Cloning the Web"
  },
  {
    "date": "November 2011",
    "date2": "2011-11",
    "frontPage": false,
    "favorite": false,
    "image": "2011-11_bloomberg.png",
    "link": "http://images.businessweek.com/slideshows/20111117/what-s-for-dinner-bugs",
    "pdf": null,
    "publication": "Bloomberg Businessweek",
    "title": "What's for Dinner? Bugs"
  },
  {
    "date": "July 2010",
    "date2": "2010-07",
    "frontPage": false,
    "favorite": false,
    "image": "2010-07_bloomberg.png",
    "link": "http://www.businessweek.com/magazine/content/10_30/b4188020389751.htm",
    "pdf": null,
    "publication": "Bloomberg Businessweek",
    "title": "A Test to Identify Entrepreneurs"
  },
  {
    "date": "June 2011",
    "date2": "2011-06",
    "frontPage": false,
    "favorite": false,
    "image": "2011-06_bloomberg.jpg",
    "link": "http://www.businessweek.com/magazine/content/11_25/b4233058977933.htm",
    "pdf": "2011-06_bloomberg.pdf",
    "publication": "Bloomberg Businessweek",
    "title": "God's MBAs: Why Mormon Missions Produce Leaders"
  },
  {
    "date": "August 2008",
    "date2": "2008-08",
    "frontPage": true,
    "favorite": true,
    "image": "2008-08_the-new-york-times-magazine.png",
    "link": "http://www.nytimes.com/2008/08/03/magazine/03wwln-guestsafire-t.html",
    "pdf": null,
    "publication": "The New York Times Magazine",
    "title": "Me, Myself and I"
  },
  {
    "date": "August 2007",
    "date2": "2007-08",
    "frontPage": false,
    "favorite": false,
    "image": "2007-08_the-new-york-times.png",
    "link": "http://www.nytimes.com/2007/08/05/arts/design/05wint.html?pagewanted=all",
    "pdf": null,
    "publication": "The New York Times",
    "title": "With a Hammer, Finding Ghosts in the Glass"
  },
  {
    "date": "September 2003",
    "date2": "2003-09",
    "frontPage": true,
    "favorite": true,
    "image": "2003-09_the-new-york-times.jpg",
    "link": "http://www.nytimes.com/2003/09/26/opinion/saddam-hussein-is-in-my-kitchen.html",
    "pdf": null,
    "publication": "The New York Times",
    "title": "Saddam Hussein Is in My Kitchen"
  },
  {
    "date": "November 2009",
    "date2": "2009-11",
    "frontPage": false,
    "favorite": false,
    "image": "2009-11_der-spiegel.png",
    "link": "http://www.spiegel.de/international/germany/stealth-travel-in-the-ussr-the-east-german-adventurers-who-escaped-the-other-way-a-659806.html",
    "pdf": null,
    "publication": "Der Spiegel",
    "title": "Stealth Travel In The USSR"
  },
  {
    "date": "July 2009",
    "date2": "2009-07",
    "frontPage": false,
    "favorite": false,
    "image": "2009-07_the-daily-beast.png",
    "link": "http://www.thedailybeast.com/articles/2009/07/08/communist-couture.html",
    "pdf": null,
    "publication": "The Daily Beast",
    "title": "Communist Couture"
  },
  {
    "date": "September 2011",
    "date2": "2011-09",
    "frontPage": false,
    "favorite": false,
    "image": "2011-09_bloomberg.jpg",
    "link": "https://www.bloomberg.com/news/articles/2011-09-15/poker-expats-in-paradise",
    "pdf": "2011-09_bloomberg.pdf",
    "publication": "Bloomberg Businessweek",
    "title": "On the Run With the Queen of Hearts"
  }

]
export default allArticles
